<script>
import ThemeForm from "@/views/menu/configurations/platform-theme/themeForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    themeService: "themeService",
    toastService: "toastService"
  },
})
export default class PlatformColorPaletteEdit extends mixins(ThemeForm) {
  formId = "new-theme-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      await this.themeService.update(this.model.id, this.model);
      this.$router.push("/configurations/color-palette/list");
      // eslint-disable-next-line no-undef
      toastService.success(this.translations.success.color_palette_updated);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
      // eslint-disable-next-line no-undef
      toastService.error(this.translations.errors.somethingWrong);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.color_palette_edit;
  }

  async created() {
    this.model = await this.themeService.read(this.$route.params.id);
    this.isReady = true;
  }
}
</script>
